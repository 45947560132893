var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.entity.entityType === 'player' && _vm.health.curHp <= 0)?_c('div',{staticClass:"d-flex"},[[(_vm.camp_data.stable)?_c('div',{staticClass:"green"},[_vm._m(0)]):(_vm.camp_data.dead)?_c('div',{staticClass:"red"},[_vm._m(1)]):_c('div',{staticClass:"hp d-flex justify-content-start"},_vm._l((_vm.camp_data.saves),function(check,i){return _c('div',{key:i,staticClass:"mr-1"},[(_vm.playerSettings.hide_death_saves && i > 0)?_c('i',{staticClass:"fas fa-question-circle neutral-2"}):[_c('span',{directives:[{name:"show",rawName:"v-show",value:(check === 'succes'),expression:"check === 'succes'"}],staticClass:"save green"},[_c('i',{staticClass:"fas fa-check-circle",attrs:{"aria-hidden":"true"}})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(check === 'fail'),expression:"check === 'fail'"}],staticClass:"save red"},[_c('i',{staticClass:"fas fa-times-circle",attrs:{"aria-hidden":"true"}})])]],2)}),0)]],2):(
			(_vm.playerSettings.health === undefined &&
				(_vm.entity.entityType === 'player' || _vm.entity.entityType === 'companion')) ||
			(_vm.entity.entityType === 'npc' && _vm.displayNPCField('health', _vm.entity) === true)
		)?_c('div',[(_vm.campPlayers && _vm.players && _vm.npcs && (_vm.health.curHp > 0 || _vm.entity.entityType == 'npc'))?_c('div',[_c('span',{staticClass:"hp"},[(_vm.isTransformed(_vm.entity, _vm.camp_data))?_c('span',{staticClass:"mr-1"},[_c('i',{staticClass:"fas fa-paw-claws mr-2",attrs:{"aria-hidden":"true"}}),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center middle"}},[_vm._v(" Transformed ")])],1):_vm._e(),_c('span',{staticClass:"current",class:{
						red: _vm.percentage(_vm.health.curHp, _vm.health.maxHp) <= 33,
						orange:
							_vm.percentage(_vm.health.curHp, _vm.health.maxHp) > 33 &&
							_vm.percentage(_vm.health.curHp, _vm.health.maxHp) <= 76,
						green: _vm.percentage(_vm.health.curHp, _vm.health.maxHp) > 76,
					}},[_c('hk-animated-integer',{attrs:{"value":_vm.health.curHp}})],1),_c('span',{staticClass:"neutral-2"},[_vm._v("/")]),_c('span',{class:{
						green:
							(_vm.entity.entityType === 'player' || _vm.entity.entityType === 'companion') &&
							_vm.camp_data.maxHpMod > 0,
						red:
							(_vm.entity.entityType === 'player' || _vm.entity.entityType === 'companion') &&
							_vm.camp_data.maxHpMod < 0,
					}},[_vm._v(_vm._s(_vm.health.maxHp)+"\n\t\t\t\t")]),(_vm.health.tempHp)?[_vm._v(" +"+_vm._s(_vm.health.tempHp)+" ")]:_vm._e()],2)]):_vm._e()]):(
			(_vm.playerSettings.health === 'obscured' &&
				(_vm.entity.entityType === 'player' || _vm.entity.entityType === 'companion')) ||
			(_vm.entity.entityType == 'npc' && _vm.displayNPCField('health', _vm.entity) === 'obscured')
		)?_c('div',[(_vm.health.curHp == 0)?[_c('i',{staticClass:"fas fa-skull-crossbones red",attrs:{"aria-hidden":"true"}})]:_c('i',{staticClass:"fas",class:{
				'green fa-heart': _vm.percentage(_vm.health.curHp, _vm.health.maxHp) == 100,
				'orange fa-heart-broken':
					_vm.percentage(_vm.health.curHp, _vm.health.maxHp) < 100 &&
					_vm.percentage(_vm.health.curHp, _vm.health.maxHp) > 33,
				'red fa-heartbeat': _vm.percentage(_vm.health.curHp, _vm.health.maxHp) <= 33,
			},attrs:{"aria-hidden":"true"}})],2):_c('div',[_c('span',{staticClass:"neutral-2"},[(_vm.health.curHp == 0)?[_c('i',{staticClass:"fas fa-skull-crossbones red",attrs:{"aria-hidden":"true"}})]:[_vm._v("? ? ?")]],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"fas fa-fist-raised",attrs:{"aria-hidden":"true"}}),_vm._v(" Stable")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"fas fa-skull-crossbones",attrs:{"aria-hidden":"true"}}),_vm._v(" Dead")])
}]

export { render, staticRenderFns }